import { app } from "./types";
import { setTheme } from "../SERVICES/user-service";

const initialState = {
  title: app.TITLE,
  subtitle: app.SUBTITLE,
  description: app.DESCRIPTION,
  client_url: app.CLIENT_URL,
  server_url: app.SERVER_URL,
  default_language: app.DEFAULT_LANGUAGE,
  keyword: "",
  is_searching: false,
  default_theme: app.DEFAULT_THEME,
  current_grid: null,
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case app.DEFAULT_THEME:
      state.default_theme = action.theme;
      setTheme(action.theme);
      return { ...state };
    case app.CHANGE_GRID_VIEW:
      state.current_grid = action.grid;
      return { ...state };
    default:
      return state;
  }
};
export default todos;
