export enum app {
  TITLE = "HOMETUTOR V4",
  SUBTITLE = "HOMETUTOR V4",
  DESCRIPTION = "Malaysia's best online learning for SPM, PT3, UPSR, Lower Primary",
  CLIENT_URL = "https://hometutor.com.my",
  SERVER_URL = "https://api-webrtc.hometutor.com.my",
  //SERVER_URL = "http://localhost:3636",
  // HT_OLD_DOMAIN = "http://dev.hometutor.com.my",
  HT_OLD_DOMAIN = "https://hometutor.com.my",
  DEFAULT_LANGUAGE = "my",
  DEFAULT_THEME = "dark",
  CHANGE_GRID_VIEW = "CHANGE_GRID_VIEW",
}

export enum drawer {
  DRAWER_TOOGLE = "DRAWER_TOOGLE",
}

export enum user {
  USER_INIT = "USER_INIT",
  USER_INIT_ASYNC = "USER_INIT_ASYNC",
}
