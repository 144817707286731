// import { app } from "./type";

const initialState = {
  status: false, // collape expand
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default todos;
