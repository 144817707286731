import { applyMiddleware, compose, combineReducers, createStore } from "redux";
import { History } from "history";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import app from "./app-reducer";
import drawer from "./drawer-reducer";
import user from "./user-reducer";
import classroom from "../ROUTES/classroom/store/classroom-reducer";

import rootSaga from "./root-sagas";

const createRootReducer = (history: History) =>
  combineReducers({
    redux: combineReducers({
      app,
      drawer,
      user,
      modules: combineReducers({ classroom }),
    }),
    router: connectRouter(history),
  });

const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

export default function configureStore(history: History) {
  //Init middlewares
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  //Init enhancer
  const enhancer = compose(applyMiddleware(...middlewares));

  //Store creation
  const store = createStore(createRootReducer(history), enhancer);
  sagaMiddleware.run(rootSaga);
  return store;
}
