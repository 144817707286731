import React from "react";
import MainRouter from "../ROUTES";

import Theme from "./App-theme";

function App(props: any) {
  return (
    <Theme>
      <MainRouter />
    </Theme>
  );
}

export default App;
