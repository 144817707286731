import { call, takeLatest, put, delay } from "redux-saga/effects";
import { app, user } from "./types";
import { getUserInit } from "./user-axios";

function* async__getUserInit(action: any) {
  try {
    yield put({
      type: user.USER_INIT_ASYNC,
      data: {},
      is_loading: true,
    });
    const data = yield call(getUserInit, action);

    yield put({
      type: user.USER_INIT_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}

export function* watch__UserAll() {
  yield takeLatest(user.USER_INIT, async__getUserInit);
}
