import { call, takeLatest, put, delay } from "redux-saga/effects";
import { classroom } from "./types";
import { getUserClasses, getUserClass } from "./classroom-axios";

function* async__getUserClasses(action: any) {
  try {
    yield put({
      type: classroom.MY_CLASS_LIST_ASYNC,
      data: [],
      is_loading: true,
    });
    const data = yield call(getUserClasses, action);

    yield put({
      type: classroom.MY_CLASS_LIST_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}
function* async__getUserClass(action: any) {
  try {
    yield put({
      type: classroom.MY_CLASS_ASYNC,
      data: [],
      is_loading: true,
    });
    const data = yield call(getUserClass, action);

    yield put({
      type: classroom.MY_CLASS_ASYNC,
      data: data,
      is_loading: false,
    });
  } catch (error) {
    throw error;
  }
}
export function* watch__ClassroomAll() {
  yield takeLatest(classroom.MY_CLASS_LIST, async__getUserClasses);
  yield takeLatest(classroom.MY_CLASS, async__getUserClass);
}
