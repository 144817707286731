import { classroom } from "./types";

const initialState = {
  my_classes: {
    is_loading: true,
    data: [],
  },
  my_class: {
    is_loading: true,
    data: [],
  },
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case classroom.MY_CLASS_LIST_ASYNC:
      if (!action.is_loading) {
        state.my_classes = action.data;
      }
      state.my_classes.is_loading = action.is_loading;

      return { ...state };
    case classroom.MY_CLASS_ASYNC:
      if (!action.is_loading) {
        state.my_class = action.data;
      }
      state.my_class.is_loading = action.is_loading;

      return { ...state };
    default:
      return state;
  }
};
export default todos;
