import React from "react";
import { getUserSession } from "./user-service";

export const authHeader = () => {
  // return authorization header with jwt token

  let user = JSON.parse(getUserSession());

  if (user && user.token) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: user.token,
      },
    };
  } else {
    return {};
  }
};

export const getToken = () => {
  let user = JSON.parse(getUserSession());
  if (user && user.token) {
    return user.token;
  } else {
    return {};
  }
};

export const authHeaderFormData = () => {
  // return authorization header with jwt token

  let user = JSON.parse(getUserSession());

  if (user && user.token) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: user.token,
      },
    };
  } else {
    return {};
  }
};

// export const authHeaderFormData = () => {
//   // return authorization header with jwt token

//   let user = JSON.parse(getUserSession());

//   if (user && user.token) {
//     return {
//       headers: {
//         "Content-Type":
//           "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
//         Authorization: user.token,
//       },
//     };
//   } else {
//     return {};
//   }
// };
