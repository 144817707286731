import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MainWrapper from "../CONTAINERS/layouts/main-wrapper";

import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
// import { PrivateRoute } from "./private-route";
// import { CheckPrivateRoute } from "./check-private-route";
import "../i18n";
import { PrivateRoute } from "./private-route";

const Redirect = lazy(() => import("./redirect/index"));
const Welcome = lazy(() => import("./welcome/index"));
const Streaming = lazy(() => import("./streaming/index"));
const VideoConferences = lazy(() => import("./demo-video-conferences/index"));
const MeetRoom = lazy(() => import("./meet-room/index"));

function MainRouter() {
  return (
    <Switch>
      <MainWrapper>
        <Suspense
          fallback={
            <div className="loading-wrapper">
              <Grid item xs={8} sm={5}>
                <Skeleton height={200} />
                <Skeleton height={50} />
                <Skeleton height={50} animation={false} />
                <Skeleton height={50} animation="wave" />
              </Grid>
            </div>
          }
        >
          <Route exact path="/" component={Welcome} />
          <Route
            exact
            path="/vc/:class_code/:view/:room_id?"
            component={VideoConferences}
          />
          <Route
            exact
            path="/redirect/:token/:class_code"
            component={Redirect}
          />
          <PrivateRoute
            exact
            path="/classroom/:class_code/:view/:room_id?"
            component={MeetRoom}
          />
          <Route exact path="/room/:room_id" component={Streaming} />
        </Suspense>
      </MainWrapper>
    </Switch>
  );
}

export default MainRouter;
