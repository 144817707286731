import React from "react";
import { user, app } from "./types";
import Axios from "axios";
import { authHeader } from "../SERVICES/auth-header";

export const getUserInit = (action: any) => {
  return Axios.get(`${app.SERVER_URL}/user/details`, {
    ...authHeader(),
    params: { ...action.params },
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      //   localStorage.removeItem("user");
      //   window.location.href = "/";
      throw err;
    });
};
