import React from "react";
import { app } from "../../../STORES/types";
import Axios from "axios";
import { authHeader } from "../../../SERVICES/auth-header";

export const getUserClasses = (action: any) => {
  return Axios.get(`${app.SERVER_URL}/user/my-classes`, {
    ...authHeader(),
    params: { ...action.params },
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const getUserClass = (action: any) => {
  console.log(action.params);
  return Axios.get(`${app.SERVER_URL}/user/my-class/${action.class_code}`, {
    ...authHeader(),
    params: { ...action.params },
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};
