import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { app } from "../STORES/types";
import Axios from "axios";
import { authHeader } from "./auth-header";
import { formData } from "./axios-header";
const qs = require("qs");

export const getUserSession = () => {
  return localStorage.getItem("user");
};

export const checkUserSession = () => {
  //const redux = useSelector((state: any) => state.redux);
  return localStorage.getItem("user");
};

export const userRegister = (action: any) => {
  return Axios({
    method: "post",
    url: `${app.SERVER_URL}/job.jsp?r=77000100`,
    data: qs.stringify({
      ...action,
    }),
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  })
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const userLogin = (action: any) => {
  return Axios({
    method: "post",
    url: `${app.SERVER_URL}/job.jsp?r=17000100`,
    data: qs.stringify({
      username: action.email,
      password: action.password,
    }),
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  })
    .then((resp) => resp.data)
    .catch((err) => {
      throw err;
    });
};

export const userLogout = (action: any) => {
  return Axios.get(`${app.SERVER_URL}/job.jsp?r=17000200`, {
    ...authHeader(),
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const setLanguage = (lang: string) => {
  //lang = en or my
  localStorage.setItem("lang", lang);
};

export const getLanguage = () => {
  if (localStorage.getItem("lang")) {
    return localStorage.getItem("lang");
  }
  return "my"; //default lang for e-commerce
};

export const setCartList = (data: any) => {
  localStorage.setItem("cart_list", JSON.stringify(data));
};

export const getCartList = () => {
  if (localStorage.getItem("cart_list")) {
    const data: any = localStorage.getItem("cart_list");
    return JSON.parse(data);
  }
  return {};
};

export const setMarketSearchHistory = (data: any) => {
  localStorage.setItem("market_search", JSON.stringify(data));
};

export const getMarketSearchHistory = () => {
  if (localStorage.getItem("market_search")) {
    const data: any = localStorage.getItem("market_search");
    return JSON.parse(data);
  }
  return [];
};

export const setTheme = (theme: string) => {
  //theme = light or dark
  localStorage.setItem("theme", theme);
};

export const getTheme = () => {
  if (localStorage.getItem("theme")) {
    return localStorage.getItem("theme");
  }
  return "dark"; //light or dark
};
