import { user } from "./types";

const initialState = {
  is_loading: true,
  data: {},
};

const todos = (state = initialState, action: any) => {
  switch (action.type) {
    case user.USER_INIT_ASYNC:
      if (!action.is_loading) {
        state = action.data;
      }
      state.is_loading = action.is_loading;

      return { ...state };
    default:
      return state;
  }
};
export default todos;
