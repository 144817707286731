import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { app } from "../../STORES/types";
import { getTheme } from "../../SERVICES/user-service";
import WithWidthRendering from "./with-width-rendering";

const InitialRendering = (props: any) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    const theme: any = getTheme();

    dispatch({
      type: app.DEFAULT_THEME,
      theme: theme,
    });
  }, []);

  return <div></div>;
};

interface Props {
  title: String;
  desc: string;
  redux: any;
  location: { pathname: String };
}
class mainLayout extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const props = this.props;
    const redux = props.redux;

    return (
      <div
        className={`app-view--wrapper ${redux.app.current_grid} ${redux.app.default_theme}`}
      >
        <WithWidthRendering />
        <InitialRendering />
        <Helmet>
          <title>{redux.app.title}</title>
          <link rel="canonical" href={redux.app.url} />
          <meta charSet="utf-8" />
          <meta name="description" content={redux.app.description} />
        </Helmet>

        {props.children}
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Orbitron:900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => state;
export default connect(mapStateToProps, null)(mainLayout);
